import {ListView} from '@github-ui/list-view'
import {testIdProps} from '@github-ui/test-id-props'
import {graphql, useFragment, useQueryLoader, useRelayEnvironment} from 'react-relay'
import {EmptyState} from './components/EmptyState'
import {IssuePagination} from './IssuePagination'
import styles from './RepositoryMilestone.module.css'
import {ListItemsHeader} from './components/ListItemsHeader'
import {OpenClosedMilestoneIssues} from './OpenClosedMilestoneIssues'
import type {ItemNodeType} from './types'
import {useState, useMemo, useCallback, useEffect} from 'react'
import type {MilestoneIssuesList$key} from './__generated__/MilestoneIssuesList.graphql'
import {useLocalStorage} from '@github-ui/use-safe-storage/local-storage'
import {useShiftKey} from './utils/use-shift-key'
import {IS_SERVER, ssrSafeLocation} from '@github-ui/ssr-utils'
import {prefetchIssue} from '@github-ui/issue-viewer/IssueViewerLoader'
import {IssuesIndexSecondaryGraphqlQuery, IssueRow} from '@github-ui/list-view-items-issues-prs/IssueRow'
import type {IssueRowSecondaryQuery} from '@github-ui/list-view-items-issues-prs/IssueRowSecondaryQuery'
import {PullRequestRow} from '@github-ui/list-view-items-issues-prs/PullRequestRow'
import {noop} from '@github-ui/noop'
import {startSoftNav} from '@github-ui/soft-nav/state'
import type {To, NavigateOptions} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {VALUES} from './constants/values'

type MilestoneIssuesListProps = {
  repositoryRef: MilestoneIssuesList$key
  singleKeyShortcutsEnabled?: boolean
}

export function MilestoneIssuesList({repositoryRef, singleKeyShortcutsEnabled}: MilestoneIssuesListProps) {
  const data = useFragment(
    graphql`
      fragment MilestoneIssuesList on Repository
      @argumentDefinitions(
        first: {type: "Int!"}
        query: {type: "String!"}
        skip: {type: "Int!"}
        number: {type: "Int!"}
      ) {
        search(query: $query, type: ISSUE_ADVANCED, first: $first, skip: $skip, aggregations: true) {
          issueCount
          edges {
            node {
              ... on Issue {
                id
                __typename
                number
                ...IssueRow @arguments(labelPageSize: 10, fetchRepository: false, includeMilestone: false)
              }
              ... on PullRequest {
                id
                __typename
                number
                ...PullRequestRow_pullRequest
                  @arguments(labelPageSize: 10, includeGitData: false, includeMilestone: false)
              }
            }
          }
          ...OpenClosedMilestoneIssues
        }
        milestone(number: $number) {
          number
        }
        owner {
          login
        }
        name
        id
        isArchived
        viewerCanPush
        isDisabled
        isLocked
      }
    `,
    repositoryRef,
  )
  const scoped_repository = useMemo(
    () => ({
      id: data.id,
      name: data.name,
      owner: data.owner.login,
      is_archived: data.isArchived,
      viewerCanPush: data.viewerCanPush,
      isDisabled: data.isDisabled,
      isLocked: data.isLocked,
    }),
    [data],
  )

  const [bulkJobId, setBulkJobId] = useLocalStorage<string | null>(VALUES.localStorageKeyBulkUpdateIssues, null)

  const [listHasPRs, setListHasPRs] = useState(false)

  const isBulkSupported =
    scoped_repository &&
    scoped_repository.viewerCanPush &&
    !(scoped_repository.isDisabled || scoped_repository.isLocked || scoped_repository.is_archived)

  const useBulkActions = useMemo(() => {
    return isBulkSupported && !listHasPRs
  }, [listHasPRs, isBulkSupported])

  const [checkedItems, setCheckedItems] = useState(() => new Map<string, ItemNodeType>())

  const [lastSelectedItem, setLastSelectedItem] = useState<{id: string; node: ItemNodeType}>()
  const {shiftKeyPressedRef} = useShiftKey()
  const nodes = useMemo(
    () =>
      data.search.edges
        ?.map(edge =>
          edge?.node && (edge.node.__typename === 'PullRequest' || edge.node.__typename === 'Issue') ? edge.node : null,
        )
        .filter(node => !!node) || [],
    [data],
  )
  const nodesIds = useMemo(() => nodes?.map(node => node?.id).filter(Boolean), [nodes])
  const [milestoneIssuesLazyDataRef, loadMilestoneIssuesLazyData] = useQueryLoader<IssueRowSecondaryQuery>(
    IssuesIndexSecondaryGraphqlQuery,
  )
  const hasLazyData = milestoneIssuesLazyDataRef !== null
  useEffect(() => {
    if (!IS_SERVER) {
      loadMilestoneIssuesLazyData({nodes: nodesIds, includeReactions: false})
    }
  }, [loadMilestoneIssuesLazyData, nodesIds, hasLazyData])

  const environment = useRelayEnvironment()
  const navigate = useNavigate()
  const handleNavigate = useCallback(
    async ({issueNumber}: {issueNumber: number}, to: To, navOptions?: NavigateOptions) => {
      startSoftNav('react')
      await prefetchIssue(environment, data.owner.login, data.name, issueNumber)
      return navigate(to, navOptions)
    },
    [data.name, data.owner.login, environment, navigate],
  )

  const applyShiftSelection = useCallback(
    (newCheckedItems: Map<string, ItemNodeType>, fromId: string, toId: string, selected: boolean) => {
      const fromIndex = nodes.findIndex(d => d.id === fromId)
      const toIndex = nodes.findIndex(d => d.id === toId)
      for (let i = Math.min(fromIndex, toIndex); i <= Math.max(fromIndex, toIndex); i++) {
        const d = nodes[i]
        if (d) {
          if (selected) {
            newCheckedItems.set(d.id, d)
          } else {
            newCheckedItems.delete(d.id)
          }
        }
      }
    },
    [nodes],
  )

  const itemSelected = useCallback(
    (id: string, node: ItemNodeType, selected: boolean) => {
      const newCheckedItems = new Map<string, ItemNodeType>(checkedItems)

      if (shiftKeyPressedRef.current && lastSelectedItem) {
        applyShiftSelection(newCheckedItems, lastSelectedItem.id, id, selected)
      } else {
        if (selected) {
          newCheckedItems.set(id, node)
          if (node.__typename === 'PullRequest') {
            setListHasPRs(true)
          }
        } else {
          newCheckedItems.delete(id)
        }
      }

      setCheckedItems(newCheckedItems)
      setLastSelectedItem({id, node})
    },
    [applyShiftSelection, checkedItems, lastSelectedItem, shiftKeyPressedRef],
  )

  if (!data.milestone) {
    return null
  }

  const items = nodes?.map(node => {
    const sharedRowData = {
      key: node?.id,
      isActive: false,
      isSelected: node && checkedItems.has(node.id),
      onSelect: (selected: boolean) => node && itemSelected(node.id, node, selected),
      onSelectRow: noop,
      getMetadataHref: () => "test'",
      reactionEmojiToDisplay: {reaction: '', reactionEmoji: ''},
      sortingItemSelected: '',
      onNavigate: (to: To, navOptions?: NavigateOptions) => handleNavigate({issueNumber: node.number}, to, navOptions),
      scopedRepository: scoped_repository,
      metadataRef: milestoneIssuesLazyDataRef,
    }
    if (node.__typename === 'Issue') {
      return <IssueRow issueKey={node} {...sharedRowData} key={sharedRowData.key} />
    }
    if (node.__typename === 'PullRequest') {
      return (
        <PullRequestRow
          pullRequestKey={node}
          includeGitDataFromMainQuery={false}
          {...sharedRowData}
          key={sharedRowData.key}
        />
      )
    }
  })

  const listItemsHeader = (
    <ListItemsHeader
      checkedItems={checkedItems}
      scopedRepository={scoped_repository}
      sectionFilters={<OpenClosedMilestoneIssues milestoneRef={data.search} />}
      issueCount={data.search.issueCount}
      issueNodes={nodes.filter(Boolean).reduce((arr, node) => {
        arr.push(node)
        return arr
      }, new Array<ItemNodeType>())}
      setCheckedItems={setCheckedItems}
      useBulkActions={useBulkActions}
      singleKeyShortcutsEnabled={singleKeyShortcutsEnabled}
      bulkJobId={bulkJobId}
      setBulkJobId={setBulkJobId}
    />
  )

  const rootUrl = `${ssrSafeLocation.origin}/${data.owner.login}/${data.name}`
  const issuesWithNoMilestoneUrl = `${rootUrl}/issues/?q=is%3Aissue%20state%3Aopen%20no%3Amilestone`
  const currentMilestonePathname = `/${data.owner.login}/${data.name}/milestone/${data.milestone.number}`
  const searchString = ssrSafeLocation.search
  const pathName = ssrSafeLocation.pathname
  const closedParam = 'closed=1'
  const isClosedSelectedTab = currentMilestonePathname === pathName && searchString.includes(closedParam)

  return (
    <>
      <div className={styles.milestoneListWrapper} data-hpc>
        <ListView
          {...testIdProps('repository-milestone-list-view')}
          title=""
          totalCount={data.search.issueCount}
          selectedCount={checkedItems.size}
          titleHeaderTag="h2"
          isSelectable
          metadata={listItemsHeader}
          singularUnits={'issue'}
          pluralUnits={'issues'}
        >
          {items}
          {items.length === 0 && (
            <EmptyState selected={isClosedSelectedTab ? 'closed' : 'open'} descriptionUrl={issuesWithNoMilestoneUrl} />
          )}
        </ListView>
      </div>
      <IssuePagination totalCount={data.search.issueCount} />
    </>
  )
}

try{ MilestoneIssuesList.displayName ||= 'MilestoneIssuesList' } catch {}